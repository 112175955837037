import { Injectable } from '@angular/core';
import { Config } from '../../core/interface';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private config: Config = environment;

  constructor(private http: HttpClient) {}

  getApiDocs(jsonType): Observable<any> {
    return this.http.get<any>(`${this.config.dsp_endpoint}/doc/${jsonType}.json`);
  }
}
