'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.combineReducers = undefined;
var _combineReducers2 = require('./combineReducers');
var _combineReducers3 = _interopRequireDefault(_combineReducers2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
exports.combineReducers = _combineReducers3.default;
