import { Component, DestroyRef, inject } from '@angular/core';
import { AuthService } from '../../../../auth/auth.service';
import { LayoutService } from '../../../../layout/layout.service';
import { ProfileService } from '../../../../core/services/profile.service';
import { AdxadAlerts } from '../../../../ui/modules/alerts/components/alerts/alerts.component';
import { Router } from '@angular/router';
import { APP_ROUTE } from '../../../../core/routes';
import { UiModule } from '../../../../ui/ui.module';
import { NgIf } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-receive-confirm',
  styleUrls: ['./receive-confirm.component.scss'],
  standalone: true,
  imports: [UiModule, NgIf, TranslocoDirective],
  template: ` <div
    class="receive-page"
    *transloco="let t"
  >
    <div class="receive-page__title">
      {{ t('emailNotification_title') }}
    </div>

    <div class="receive-page__text">
      <ng-container *ngIf="isOldUser">
        {{ t('emailNotification_textOldUser') }}
        <br />
        {{ t('emailNotification_lookSpam') }}
      </ng-container>

      <ng-container *ngIf="!isOldUser">
        {{ t('emailNotification_textNewUser') }}
        <br />
        {{ t('emailNotification_lookSpam') }}
        <br />
        {{ t('emailNotification_stillCantFind') }}
      </ng-container>
    </div>

    <div class="receive-page__actions">
      <button
        *ngIf="isOldUser"
        adxadStrokedButton
        (click)="closeConfirm()"
      >
        {{ t('emailNotification_closeBtn') }}
      </button>

      <button
        adxadButton
        (click)="resentConfirm()"
      >
        {{ t(isOldUser ? 'emailNotification_sendBtn' : 'emailNotification_resendBtn') }}
      </button>
    </div>
  </div>`
})
export class ReceiveConfirmComponent {
  private destroyRef = inject(DestroyRef);

  constructor(
    private authService: AuthService,
    private layoutService: LayoutService,
    private profileService: ProfileService,
    private alerts: AdxadAlerts,
    private router: Router
  ) {}

  /**
   * Recent confirm email
   */
  resentConfirm(): void {
    this.authService
      .receiveConfirmEmail()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.alerts.success(result.message, 3000);
        }
      });
  }

  /**
   * @return {boolean} Show btn if is olr user
   */
  get isOldUser(): boolean {
    return this.profileService.profile?.isOldUser;
  }

  /**
   * Hide notification before next login
   */
  closeConfirm(): void {
    this.layoutService.setHideEmailConfirmNotification();
    this.router.navigate([APP_ROUTE.tradeDesk]);
  }
}
