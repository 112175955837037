<adxad-loader
  adxadSidebarModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<adxad-sidebar-modal-container
  class="profile-view"
  *ngIf="profile && !isLoading"
>
  <adxad-sidebar-modal-header>
    <adxad-sidebar-modal-close-btn (click)="close()"></adxad-sidebar-modal-close-btn>
    <h1
      adxadSidebarModalTitle
      data-test="title"
    >
      {{ profile.basic.email }}
    </h1>
  </adxad-sidebar-modal-header>

  <adxad-sidebar-modal-body>
    <section
      adxadSidebarModalSection
      *ngIf="!roles.isManagers()"
    >
      <div
        class="profile-view__balance"
        data-test="balance"
      >
        {{ profile.balance | currency }}

        <button
          adxadStrokedButton
          class="profile-view__balance-btn"
          (click)="submitBalance()"
          *ngIf="profileService.profile?.showAddFunds"
        >
          {{ 'action_addFunds' | transloco }}
        </button>
      </div>
    </section>

    <!-- account -->
    <section
      adxadSidebarModalSection
      data-test="account"
    >
      <h4
        class="profile-view__section-title"
        (click)="isOpenSection.account = !isOpenSection.account"
      >
        {{ 'account' | transloco }}

        <span
          class="material-icons profile-view__section-title-icon"
          [class.open]="isOpenSection.account"
        >
          keyboard_arrow_down
        </span>
      </h4>

      <div
        class="row"
        *ngIf="isOpenSection.account"
        @slideToggle
      >
        <adxad-sidebar-modal-info-block
          class="col-6"
          label="{{ 'label_email' | transloco }}"
          *ngIf="profile.basic?.email"
        >
          {{ profile.basic.email }}

          <span
            [adxadTooltip]="profile.basic.isVerifiedEmail ? 'Email verified' : 'Unverified email'"
            [ngClass]="profile.basic.isVerifiedEmail ? '--verified-email' : '--unverified-email'"
            *ngIf="roles.isAffiliate()"
            class="material-icons"
          >
            {{ profile.basic.isVerifiedEmail ? 'check_circle' : 'warning' }}
          </span>
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          class="col-6"
          label="{{ 'label_role' | transloco }}"
          *ngIf="profile.basic?.roles"
        >
          {{ profile.basic.roles.value }}
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          class="col-6"
          label="{{ 'label_team' | transloco }}"
          *ngIf="profile.basic?.team"
        >
          {{ profile.basic.team }}
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          class="col-6"
          label="{{ 'label_apiKey' | transloco }}"
          *ngIf="profile.basic?.apiKey"
        >
          <adxad-api-key [value]="profile.basic.apiKey"></adxad-api-key>
        </adxad-sidebar-modal-info-block>
      </div>
    </section>

    <!-- account manager -->
    <section
      adxadSidebarModalSection
      *ngIf="profile.basic?.accountManager"
      data-test="accountManager"
    >
      <h4
        class="profile-view__section-title"
        (click)="isOpenSection.accountManager = !isOpenSection.accountManager"
      >
        {{ 'accountManager' | transloco }}

        <span
          class="material-icons profile-view__section-title-icon"
          [class.open]="isOpenSection.accountManager"
        >
          keyboard_arrow_down
        </span>
      </h4>

      <div
        *ngIf="isOpenSection.accountManager"
        @slideToggle
      >
        <adxad-manager-block [manager]="accountManager"></adxad-manager-block>
      </div>
    </section>

    <!-- sales manager -->
    <section
      adxadSidebarModalSection
      *ngIf="profile.basic?.salesManager"
      data-test="salesManager"
    >
      <h4
        class="profile-view__section-title"
        (click)="isOpenSection.salesManager = !isOpenSection.salesManager"
      >
        {{ 'salesManager' | transloco }}

        <span
          class="material-icons profile-view__section-title-icon"
          [class.open]="isOpenSection.salesManager"
        >
          keyboard_arrow_down
        </span>
      </h4>

      <div
        *ngIf="isOpenSection.salesManager"
        @slideToggle
      >
        <adxad-manager-block [manager]="salesManager"></adxad-manager-block>
      </div>
    </section>

    <!-- contacts -->
    <section
      adxadSidebarModalSection
      [hideSeparator]="isPaymentView"
      data-test="payment"
    >
      <h4
        class="profile-view__section-title"
        (click)="isOpenSection.contacts = !isOpenSection.contacts"
      >
        {{ 'contacts' | transloco }}

        <span
          class="material-icons profile-view__section-title-icon"
          [class.open]="isOpenSection.contacts"
        >
          keyboard_arrow_down
        </span>
      </h4>

      <div
        class="row"
        *ngIf="isOpenSection.contacts"
        @slideToggle
      >
        <adxad-sidebar-modal-info-block
          label="{{ 'label_phoneNumber' | transloco }}"
          class="col-12"
        >
          {{ profile.contacts.phone }}
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          label="{{ 'label_messengers' | transloco }}"
          *ngIf="profile.contacts.messengers?.length"
          class="col-6"
        >
          <div
            class="profile-view__messenger"
            *ngFor="let messenger of profile.contacts.messengers"
          >
            <img
              class="profile-view__messenger-icon"
              [src]="'/assets/images/messengers/' + messenger.type + '.svg'"
              alt="{{ messenger.info }}"
            />
            <div class="profile-view__messenger-info">{{ messenger.info }}</div>
          </div>
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          label="{{ 'label_languages' | transloco }}"
          *ngIf="profile.contacts.languages?.length"
          class="col-6"
        >
          <div class="profile-view__languages-wrapper">
            <div
              class="user__lang"
              *ngFor="let lang of profile.contacts.languages"
            >
              {{ lang }}
            </div>
          </div>
        </adxad-sidebar-modal-info-block>
      </div>
    </section>

    <!-- pay info -->
    <section
      adxadSidebarModalSection
      [hideSeparator]="true"
      *ngIf="isPaymentView"
    >
      <h4
        class="profile-view__section-title"
        (click)="isOpenSection.payment = !isOpenSection.payment"
      >
        {{ 'paymentInfo' | transloco }}

        <span
          class="material-icons profile-view__section-title-icon"
          [class.open]="isOpenSection.payment"
        >
          keyboard_arrow_down
        </span>
      </h4>

      <div
        class="payment-section"
        *ngIf="isOpenSection.payment"
        @slideToggle
      >
        <button
          adxadStrokedButton
          class="gray --change-payment-info-btn"
          (click)="openPaymentInfoForm()"
        >
          {{ 'action_editPaymentInfo' | transloco }}
        </button>

        <div
          class="profile-view__payment-warning"
          *ngIf="!profile.payment && !profile.paymentOnModeration"
        >
          <span class="material-icons">warning</span>
          <span class="profile-view__payment-warning-info"> {{ 'notification_pleaseAddPaymentInfo' | transloco }} </span>
        </div>

        <adxad-tabs>
          <adxad-tab *ngIf="profile.payment">
            <adxad-tab-label> {{ 'label_active' | transloco }} </adxad-tab-label>

            <div class="row profile-view__payment-section">
              <ng-container *ngTemplateOutlet="paymentTpl"></ng-container>
            </div>
          </adxad-tab>

          <adxad-tab *ngIf="profile.paymentOnModeration">
            <adxad-tab-label class="payment-section-title">
              {{ 'label_onModeration' | transloco }}

              <span
                class="payment-section-title__status material-icons"
                [ngClass]="'_' + profile.paymentOnModeration.displayedStatus"
                [adxadTooltip]="profile.paymentOnModeration.displayedStatus | titlecase"
              >
                warning
              </span>
            </adxad-tab-label>

            <div class="row profile-view__payment-section">
              <ng-container *ngTemplateOutlet="paymentModerationTpl"></ng-container>
            </div>
          </adxad-tab>
        </adxad-tabs>
      </div>
    </section>
  </adxad-sidebar-modal-body>

  <adxad-sidebar-modal-actions>
    <button
      adxadSidebarModalAction
      actionType="main-gray"
      (click)="openPasswordForm()"
    >
      <span class="material-icons">vpn_key</span>
      {{ 'action_changePassword' | transloco }}
    </button>

    <button
      class="profile-view__edit-btn"
      adxadSidebarModalAction
      actionType="main-blue"
      (click)="openEditForm()"
    >
      <span class="material-icons">edit</span>
      {{ 'action_edit' | transloco }}
    </button>
  </adxad-sidebar-modal-actions>
</adxad-sidebar-modal-container>

<!-- payment template -->
<ng-template #paymentTpl>
  <adxad-sidebar-modal-info-block
    class="col-6"
    label="{{ 'label_accountType' | transloco }}"
  >
    {{ profile.payment.type }}
  </adxad-sidebar-modal-info-block>

  <adxad-sidebar-modal-info-block
    class="col-6"
    label="{{ 'label_name' | transloco }}"
  >
    {{ profile.payment.name }}
  </adxad-sidebar-modal-info-block>

  <adxad-sidebar-modal-info-block
    class="col-6"
    label="{{ 'label_city' | transloco }}"
  >
    {{ profile.payment.city }}
  </adxad-sidebar-modal-info-block>

  <adxad-sidebar-modal-info-block
    class="col-6"
    label="{{ 'label_country' | transloco }}"
  >
    {{ profile.payment.countryName }}
  </adxad-sidebar-modal-info-block>

  <adxad-sidebar-modal-info-block
    class="col-6"
    label="{{ 'label_address' | transloco }}"
  >
    {{ profile.payment.address }}
  </adxad-sidebar-modal-info-block>

  <adxad-sidebar-modal-info-block
    class="col-6"
    label="{{ 'label_zipCode' | transloco }}"
  >
    {{ profile.payment.zip }}
  </adxad-sidebar-modal-info-block>

  <adxad-sidebar-modal-info-block
    *ngIf="profile.payment.vat"
    class="col-6"
    label="VAT"
  >
    {{ profile.payment.vat }}

    <span
      class="material-icons profile-view__var-warning"
      *ngIf="profile.payment.vatStatus === 3"
      adxadTooltip="{{ 'tooltips_invalidVatNumber' | transloco }}"
    >
      warning
    </span>
  </adxad-sidebar-modal-info-block>
</ng-template>

<!-- payment on moderation template -->
<ng-template #paymentModerationTpl>
  <adxad-sidebar-modal-info-block
    class="col-12"
    *ngIf="profile.paymentOnModeration.comment"
  >
    <adxad-label> {{ 'label_comment' | transloco }} </adxad-label>

    <div class="profile-view__payment-info-comment">{{ profile.paymentOnModeration.comment }}</div>
  </adxad-sidebar-modal-info-block>

  <adxad-sidebar-modal-info-block
    class="col-6"
    label="{{ 'label_accountType' | transloco }}"
  >
    {{ profile.paymentOnModeration.type }}
  </adxad-sidebar-modal-info-block>

  <adxad-sidebar-modal-info-block
    class="col-6"
    label="{{ 'label_name' | transloco }}"
  >
    {{ profile.paymentOnModeration.name }}
  </adxad-sidebar-modal-info-block>

  <adxad-sidebar-modal-info-block
    class="col-6"
    label="{{ 'label_city' | transloco }}"
  >
    {{ profile.paymentOnModeration.city }}
  </adxad-sidebar-modal-info-block>

  <adxad-sidebar-modal-info-block
    class="col-6"
    label="{{ 'label_country' | transloco }}"
  >
    {{ profile.paymentOnModeration.countryName }}
  </adxad-sidebar-modal-info-block>

  <adxad-sidebar-modal-info-block
    class="col-6"
    label="{{ 'label_address' | transloco }}"
  >
    {{ profile.paymentOnModeration.address }}
  </adxad-sidebar-modal-info-block>

  <adxad-sidebar-modal-info-block
    class="col-6"
    label="{{ 'label_zipCode' | transloco }}"
  >
    {{ profile.paymentOnModeration.zip }}
  </adxad-sidebar-modal-info-block>

  <adxad-sidebar-modal-info-block
    *ngIf="profile.paymentOnModeration.vat"
    class="col-6"
    label="VAT"
  >
    {{ profile.paymentOnModeration.vat }}

    <span
      class="material-icons profile-view__var-warning"
      *ngIf="profile.paymentOnModeration.vatStatus === 3"
      adxadTooltip="{{ 'tooltips_invalidVatNumber' | transloco }}"
    >
      warning
    </span>
  </adxad-sidebar-modal-info-block>
</ng-template>
