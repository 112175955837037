import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { APP_ROUTE } from '../routes';
import { ProfileService } from '../services/profile.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private profileService: ProfileService,
    private router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (state.url.indexOf('confirm_email_request') !== -1) {
      return true;
    }

    if (this.profileService.hasToken) {
      this.router.navigate([APP_ROUTE.tradeDesk]);
      return false;
    }

    return true;
  }
}
