'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _immutable = require('immutable');
var _immutable2 = _interopRequireDefault(_immutable);
var _utilities = require('./utilities');
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
exports.default = function (reducers) {
  var getDefaultState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _immutable2.default.Map;
  var reducerKeys = Object.keys(reducers);

  // eslint-disable-next-line space-infix-ops
  return function () {
    var inputState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : getDefaultState();
    var action = arguments[1];

    // eslint-disable-next-line no-process-env
    if (process.env.NODE_ENV !== 'production') {
      var warningMessage = (0, _utilities.getUnexpectedInvocationParameterMessage)(inputState, reducers, action);
      if (warningMessage) {
        // eslint-disable-next-line no-console
        console.error(warningMessage);
      }
    }
    return inputState.withMutations(function (temporaryState) {
      reducerKeys.forEach(function (reducerName) {
        var reducer = reducers[reducerName];
        var currentDomainState = temporaryState.get(reducerName);
        var nextDomainState = reducer(currentDomainState, action);
        (0, _utilities.validateNextState)(nextDomainState, reducerName, action);
        temporaryState.set(reducerName, nextDomainState);
      });
    });
  };
};
module.exports = exports['default'];
