import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NavigationEnd, Router } from '@angular/router';
import { GlobicaService } from './core/services/globica.service';
import { AppLocalesService } from './core/services/app-locales.service';
import { ProfileService } from './core/services/profile.service';
import { filter } from 'rxjs/operators';
import { APP_ROUTE } from './core/routes';
import { ReplaySessionService } from './core/services/replay-session.service';
import { ProfileDetail } from './core/interface';
import { NavigationService } from './core/services/navigation.service';
import { GuiModule } from './gui/gui.module';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-dsp',
  standalone: true,
  template: `
    <adxad-cookies-popup />
    @if (isLoading) {
      <adxad-loader
        class="main-loader"
        [diameter]="40"
      />
    } @else {
      <router-outlet />
    }
  `,
  imports: [GuiModule],
  styles: [
    `
      :host {
        min-height: 100%;
      }
      .main-loader {
        display: block;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
      }
    `
  ]
})
export class AppComponent implements OnInit {
  private gtmService = inject(GoogleTagManagerService);
  private router = inject(Router);
  private appLocalesService = inject(AppLocalesService);
  private globicaService = inject(GlobicaService);
  private profileService = inject(ProfileService);
  private openReplayService = inject(ReplaySessionService);
  private navService = inject(NavigationService);
  private destroyRef = inject(DestroyRef);

  public isLoading = false;
  public profile: ProfileDetail = null;

  ngOnInit(): void {
    this.appLocalesService.listenLocale();
    this.appLocalesService.init();
    this.globicaService.init();
    this.openReplayService.start();
    this.navService.init();

    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });

    this.profileService.profile$
      .pipe(
        filter(value => !!value),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: profile => {
          this.isLoading = false;
          this.profile = profile;
        }
      });

    if (this.profileService.hasToken) {
      this.isLoading = true;

      if (this.profileService.isTokenExpired) {
        this.profileService.refreshToken().subscribe({
          error: () => {
            this.profileService.logout();
            this.router
              .navigate([APP_ROUTE.auth], {
                queryParams: { return: this.router.url }
              })
              .then(() => {
                this.isLoading = false;
              });
          }
        });
      } else {
        this.profileService.loadProfile();
      }
    }
  }
}
