import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ProfileService } from '../services/profile.service';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const profileService: ProfileService = inject(ProfileService);

  if (req.url.includes('auth') || !profileService.hasToken) {
    return next(req);
  }

  req = req.clone({
    setHeaders: {
      Authorization: `Bearer ${profileService.accessToken}`
    }
  });

  return next(req);
};
