<ng-container *transloco="let t">
  <h2>{{ t('addYourContacts') }}</h2>
  <p>{{ t('weNeedYourContactForBetterWork') }}</p>

  <adxad-loader
    adxadModalLoader
    [diameter]="40"
    *ngIf="isLoading"
  />

  <form
    [formGroup]="form"
    *ngIf="!isLoading"
  >
    <ng-container formGroupName="messengers">
      <adxad-form-field class="field-group">
        <adxad-select
          label="{{ t('label_messengers') }}"
          placeholder="{{ t('placeholder_selectMessenger') }}"
          formControlName="type"
          [options]="dict.messengers.data"
        ></adxad-select>
        <app-show-errors [control]="form.get('messengers.type')" />
      </adxad-form-field>

      <adxad-form-field class="field-group">
        <adxad-input
          formControlName="info"
          label="{{ t('label_account') }}"
          placeholder="{{ t('placeholder_enterAccount') }}"
        ></adxad-input>
        <app-show-errors [control]="form.get('messengers.info')" />
      </adxad-form-field>
    </ng-container>

    <h4 class="user-form__title">{{ t('howDidYouHearAboutUs') }}</h4>

    <ng-container formGroupName="feedback">
      <adxad-form-field class="field-group">
        <adxad-select
          [translateOptions]="true"
          label="{{ 'label_select' | transloco }}"
          formControlName="type"
          [options]="dict.feedback.data"
          placeholder="{{ t('placeholder_selectVariant') }}"
          (selectOption)="onChangeVariant($event)"
        ></adxad-select>
        <app-show-errors [control]="form.get('feedback.type')"></app-show-errors>
      </adxad-form-field>

      <adxad-form-field
        class="field-group"
        *ngIf="show === 1"
      >
        <adxad-select
          label="{{ t('label_selectSocial') }}"
          placeholder="{{ t('placeholder_socials') }}"
          formControlName="info"
          [options]="dict.socials.data"
        ></adxad-select>
        <app-show-errors [control]="form.get('feedback.info')" />
      </adxad-form-field>

      <adxad-form-field
        class="field-group"
        *ngIf="show === 2"
      >
        <adxad-input
          [label]="t(feedbackPlaceholder)"
          formControlName="info"
        ></adxad-input>
        <app-show-errors [control]="form.get('feedback.info')" />
      </adxad-form-field>
    </ng-container>

    <button
      (click)="submit()"
      [disabled]="!form.valid || !form.dirty"
      adxadButton
      class="w100"
    >
      {{ t('action_save') }}
    </button>
  </form>
</ng-container>
