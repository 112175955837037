import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ProfileDetail } from '../../../core/interface';
import { LayoutService } from '../../layout.service';
import { ProfileService } from '../../../core/services/profile.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-hide-menu-layout',
  templateUrl: './hide-menu.layout.html',
  styleUrls: ['./hide-menu.layout.scss']
})
export class HideMenuLayout implements OnInit {
  public profile: ProfileDetail;

  private destroyRef = inject(DestroyRef);

  constructor(
    public layoutService: LayoutService,
    public profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.profileService.profile$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: profile => {
        this.profile = profile;
      }
    });
  }
}
