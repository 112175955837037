import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Dicts, ProfileDetail } from '../../../../core/interface';
import { DspService } from '../../../../core/services/dsp.service';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { AdxadAlerts } from '../../../../ui/modules/alerts/components/alerts/alerts.component';
import { Router } from '@angular/router';
import { ProfileService } from '../../../../core/services/profile.service';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { GlobicaService } from '../../../../core/services/globica.service';
import { UiModule } from '../../../../ui/ui.module';
import { GuiModule } from '../../../../gui/gui.module';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-contact-quiz',
  standalone: true,
  templateUrl: './contact-quiz.component.html',
  imports: [TranslocoDirective, UiModule, ReactiveFormsModule, GuiModule],
  styleUrls: ['./contact-quiz.component.scss']
})
export class ContactQuizComponent implements OnInit {
  public form: UntypedFormGroup;
  public dict = {
    feedback: {
      data: [
        {
          id: 'searchSystems',
          value: 'searchSystems'
        },
        {
          id: 'forumsMessengers',
          value: 'forumsMessengers'
        },
        {
          id: 'friends',
          value: 'someonesRecommendation'
        },
        {
          id: 'ourManager',
          value: 'ourManager'
        },
        {
          id: 'socialNetworks',
          value: 'socialNetworks'
        },
        {
          id: 'other',
          value: 'other'
        }
      ]
    },
    socials: {
      data: [
        {
          id: 'facebook',
          value: 'facebook.com'
        },
        {
          id: 'linkedin',
          value: 'linkedin.com'
        },
        {
          id: 'twitter',
          value: 'twitter.com'
        }
      ]
    }
  } as Dicts;
  public isLoading = false;
  public feedbackPlaceholder = '';
  public show: number;

  private profile: ProfileDetail = null;
  private destroyRef = inject(DestroyRef);

  constructor(
    private dspService: DspService,
    private fb: UntypedFormBuilder,
    private alerts: AdxadAlerts,
    private router: Router,
    private profileService: ProfileService,
    private translate: TranslocoService,
    public globicaService: GlobicaService
  ) {
    this.profileService.profile$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(profile => !!profile)
      )
      .subscribe(profile => (this.profile = profile));
  }

  ngOnInit(): void {
    this.createForm();
  }

  /**
   * Get 'feedback' form control
   */
  get info(): UntypedFormGroup {
    return this.form.get('feedback.info') as UntypedFormGroup;
  }

  /**
   * Create form
   */
  createForm() {
    this.form = this.fb.group({
      messengers: this.fb.group({
        type: ['', Validators.required],
        info: ['', [Validators.required, Validators.minLength(3)]]
      }),
      feedback: this.fb.group({
        type: ['', Validators.required],
        info: ['', [Validators.required, Validators.minLength(3)]]
      })
    });

    this.loadMessengersDict();

    this.form.statusChanges.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged()).subscribe(status => {
      if (status === 'VALID') {
        this.globicaService.trackEventGoals('buttonRegisterContactsSave_enable');
      }
    });

    this.globicaService.trackFormControlsStatus(this.form, 'formContacts', 'invalid');
  }

  /**
   * Load messengers dictionary
   */
  loadMessengersDict(): void {
    this.isLoading = true;

    const request = {
      limit: 200,
      page: 1
    };

    this.dspService
      .getMessengers(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => {
        this.isLoading = false;
        this.dict.messengers = result;
      });
  }

  /**
   * Show/Hide advanced fields
   *
   * @param {string} value - selected value
   */
  onChangeVariant(value: string): void {
    const validators = [Validators.required, Validators.minLength(2)];

    switch (value) {
      case 'socialNetworks':
        this.info.reset();
        this.info.enable();
        this.info.setValidators(validators);
        this.show = 1;
        break;
      case 'forumsMessengers':
        this.feedbackPlaceholder = 'insertForumsMessengers';
        this.show = 2;
        this.info.reset();
        this.info.enable();
        this.info.setValidators(validators);
        break;
      case 'ourManager':
        this.feedbackPlaceholder = 'insertOurManager';
        this.show = 2;
        this.info.reset();
        this.info.enable();
        this.info.setValidators(validators);
        break;
      case 'other':
        this.feedbackPlaceholder = '';
        this.info.reset();
        this.info.enable();
        this.info.clearValidators();
        this.show = 2;
        break;
      default:
        this.info.disable();
        this.show = 0;
        break;
    }

    this.info.updateValueAndValidity();
  }

  /**
   * Submit form
   */
  submit(): void {
    this.globicaService.trackEventGoals('buttonRegisterContactsSave_click');

    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.profileService
      .getFeedback(this.form.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ status }) => {
          if (status === 'OK') {
            const profile = {
              ...this.profile,
              surveyForm: false
            };
            this.profileService.setProfile(profile);
            this.alerts.success(this.translate.translate('alert_thankYou'), 3000);
            this.router.navigateByUrl('/');
          } else {
            this.isLoading = false;
            this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
          }
        },
        error: () => (this.isLoading = false)
      });
  }
}
