import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, TitleStrategy } from '@angular/router';
import { routes } from './routes';
import { PagesTitleService } from './core/services/page-title.service';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { environment } from '../environments/environment';
import { provideTransloco, TranslocoTestingModule } from '@jsverse/transloco';
import { provideTranslocoPersistLang } from '@jsverse/transloco-persist-lang';
import { TranslocoHttpLoader } from './transloco/transloco-loader';
import { LayoutModule } from './layout/layout.module';
import { AuthModule } from './auth/auth.module';
import { TradeDeskModule } from './modules/trade-desk/trade-desk.module';
import { UsersModule } from './modules/users/users.module';
import { ReportsModule } from './modules/reports/reports.module';
import { ModerationModule } from './modules/moderation/moderation.module';
import { PaymentsModule } from './modules/payments/payments.module';
import { ExchangeModule } from './modules/exchange/exchange.module';
import { SettingsModule } from './modules/settings/settings.module';
import { HelpModule } from './modules/help/help.module';
import { GuiModule } from './gui/gui.module';
import { ApiModule } from './modules/api/api.module';
import { tokenInterceptor } from './core/interceptor/token.interceptor';
import { SentryService } from './core/services/sentry.service';
import { OpenReplayHttpConfigInterceptor } from './core/interceptor/open-replay-http-config.interceptor';
import { httpErrorsInterceptor } from './core/interceptor/http-errors.interceptor';
import { MediaQueryService } from './core/services/media-query.service';
import * as Sentry from '@sentry/angular';

export function getLangFn({ cachedLang }) {
  return cachedLang;
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(withInterceptors([tokenInterceptor, httpErrorsInterceptor])),
    provideAnimations(),
    provideAnimationsAsync(),
    provideRouter(routes),
    importProvidersFrom(
      TranslocoTestingModule,
      LayoutModule,
      AuthModule,
      TradeDeskModule,
      UsersModule,
      ReportsModule,
      ModerationModule,
      PaymentsModule,
      ExchangeModule,
      SettingsModule,
      HelpModule,
      GuiModule,
      ApiModule
    ),
    provideTransloco({
      config: {
        availableLangs: ['en', 'ja', 'zh-cn', 'zh-tw', 'es', 'uk'],
        defaultLang: 'en',
        fallbackLang: 'en',
        reRenderOnLangChange: true,
        prodMode: environment.production,
        failedRetries: 1,
        missingHandler: {
          logMissingKey: false
        }
      },
      loader: TranslocoHttpLoader
    }),
    provideTranslocoPersistLang({
      getLangFn,
      storage: {
        useValue: localStorage
      }
    }),
    {
      provide: 'googleTagManagerId',
      useValue: 'GTM-TPRTK9L'
    },
    {
      provide: TitleStrategy,
      useClass: PagesTitleService
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (isMobile: MediaQueryService, sentry: SentryService) => () => {
        isMobile.initCheck();
        sentry.initSentry();
      },
      multi: true,
      deps: [MediaQueryService, SentryService]
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: true
      })
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OpenReplayHttpConfigInterceptor,
      multi: true
    }
  ]
};
