import { DestroyRef, Injectable, OnDestroy } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class AppLocalesService {
  private localeParamName = 'lang';
  public availableLocales = [];

  constructor(
    private translate: TranslocoService,
    private router: Router,
    private destroyRef: DestroyRef
  ) {}

  /**
   * Initialization service
   */
  init() {
    this.translate.langChanges$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.updateUrl());

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: NavigationEnd) => {
        const url = new URL((document as any).location);
        this.listenLocale(`${url.origin}/${event.url}`);
        this.updateUrl();
      });
  }

  /**
   * Update routes
   */
  private updateUrl() {
    const url = new URL((document as any).location);
    const params = url.searchParams;
    const appCurrentLang = this.translate.getActiveLang();

    if (params.has(this.localeParamName)) {
      params.set(this.localeParamName, appCurrentLang);
    } else {
      params.append(this.localeParamName, appCurrentLang);
    }

    history.replaceState(null, '', url.pathname + '?' + params.toString());
  }

  /**
   * Listen locale from url
   *
   * @param toUrl
   */
  listenLocale(toUrl?: string) {
    let appLang = localStorage.getItem('translocoLang');
    const initUrl = toUrl !== undefined ? toUrl : (document as any).location.href;
    const url = new URL(initUrl);
    const params = url.searchParams;
    let language = params.get(this.localeParamName);
    this.availableLocales = this.translate.getAvailableLangs();

    if (this.availableLocales.includes(language) && appLang !== language) {
      this.translate.setActiveLang(language);
    }
  }
}
