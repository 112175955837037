import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdxadAlerts } from '../../../ui/modules/alerts/components/alerts/alerts.component';
import { AdxadModalConfig } from '../../../ui/modules/modal/modal-config';
import { AdxadModalRef } from '../../../ui/modules/modal/modal-ref';
import { ProfileService } from '../../../core/services/profile.service';
import { TranslocoService } from '@jsverse/transloco';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss']
})
export class PasswordFormComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = false;
  public isLoadingSubmit = false;
  public user;
  public showPassword = false;
  private destroyRef = inject(DestroyRef);

  constructor(
    private profileService: ProfileService,
    private fb: UntypedFormBuilder,
    private alerts: AdxadAlerts,
    private config: AdxadModalConfig,
    private modalRef: AdxadModalRef,
    private translate: TranslocoService
  ) {}

  ngOnInit(): void {
    this.user = this.config.data;
    this.createForm();
  }

  /**
   * Create change password form
   */
  createForm(): void {
    this.form = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  /**
   * Submit change password
   */
  submit(): void {
    if (!this.form.valid || this.isLoadingSubmit) {
      return;
    }
    this.isLoadingSubmit = true;

    this.profileService
      .changePassword(this.form.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          if (result.status === 'OK') {
            this.alerts.success(this.translate.translate('alert_passwordChanged'), 3000);
            this.close();
          }
        },
        error: () => (this.isLoadingSubmit = false)
      });
  }

  /**
   * Close modal
   */
  close(): void {
    this.modalRef.close();
  }
}
