<adxad-loader
  adxadModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="close()"></adxad-modal-close-btn>

  <adxad-modal-header>
    <h2
      adxadModalTitle
      data-test="title"
    >
      {{ 'modal_titleChangePassword' | transloco }}
    </h2>
  </adxad-modal-header>

  <adxad-modal-content [formGroup]="form">
    <adxad-form-field class="field-group">
      <adxad-input
        label="{{ 'label_oldPassword' | transloco }}"
        formControlName="oldPassword"
        type="password"
        placeholder="{{ 'placeholder_enterOldPassword' | transloco }}"
        data-test="old-password-field"
        [autofocus]="true"
      />
      <app-show-errors [control]="form.controls.oldPassword"></app-show-errors>
    </adxad-form-field>

    <adxad-form-field class="field-group">
      <adxad-input
        label="{{ 'label_newPassword' | transloco }}"
        formControlName="newPassword"
        [type]="showPassword ? 'text' : 'password'"
        placeholder="{{ 'placeholder_enterNewPassword' | transloco }}"
        [suffix]="true"
        data-test="new-password-field"
      >
        <span
          class="material-icons adxad-suffix pointer"
          (click)="showPassword = !showPassword"
        >
          {{ showPassword ? 'visibility_off' : 'visibility' }}
        </span>
      </adxad-input>
      <app-show-errors [control]="form.controls.newPassword"></app-show-errors>
    </adxad-form-field>
  </adxad-modal-content>

  <adxad-modal-actions class="row">
    <div class="col">
      <button
        adxadStrokedButton
        (click)="close()"
        class="w100"
        data-test="cancel-btn"
      >
        {{ 'action_cancel' | transloco }}
      </button>
    </div>

    <div class="col">
      <adxad-submit-button
        [disabled]="!form.valid || !form.dirty || isLoadingSubmit"
        (click)="submit()"
        [isLoading]="isLoadingSubmit"
        data-test="save-btn"
      >
        {{ 'action_save' | transloco }}
      </adxad-submit-button>
    </div>
  </adxad-modal-actions>
</ng-container>
