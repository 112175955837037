import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GuiModule } from '../gui/gui.module';
import { ProfileViewComponent } from './components/profile-view/profile-view.component';
import { ProfileFormComponent } from './components/profile-form/profile-form.component';
import { PasswordFormComponent } from './components/password-form/password-form.component';
import { MenuComponent } from './components/menu/menu.component';
import { TranslocoModule } from '@jsverse/transloco';
import { HeaderComponent } from './components/header/header.component';
import { HideMenuLayout } from './layouts/hide-menu-layout/hide-menu.layout';
import { MainLayoutComponent } from './layouts/main-layout/main.layout';

const ENTRY_LAYOUTS = [MainLayoutComponent, HideMenuLayout];

const ENTRY_COMPONENTS = [ProfileViewComponent, ProfileFormComponent, PasswordFormComponent, MenuComponent, HeaderComponent];

@NgModule({
  imports: [GuiModule, RouterModule, TranslocoModule],
  exports: [HeaderComponent, MenuComponent],
  declarations: [...ENTRY_COMPONENTS, ...ENTRY_LAYOUTS]
})
export class LayoutModule {}
