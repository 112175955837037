import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ProfileDetail } from '../../../core/interface';
import { LayoutService } from '../../layout.service';
import { ProfileService } from '../../../core/services/profile.service';
import { AdxadSidebarModal } from '../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { QueryParamsService } from '../../../core/services/query-params.service';
import { ProfileViewComponent } from '../../components/profile-view/profile-view.component';
import { UserViewComponent } from '../../../modules/users/modules/users/components/user-view/user-view.component';
import { ProjectViewComponent } from '../../../modules/trade-desk/modules/projects/components/project-view/project-view.component';
import { CampaignViewComponent } from '../../../modules/trade-desk/modules/campaigns/components/campaign-view/campaign-view.component';
import { CreativeViewComponent } from '../../../modules/trade-desk/modules/creatives/components/creative-view/creative-view.component';
import { ModerationCreativeViewComponent } from '../../../modules/moderation/components/moderation-creative-view/moderation-creative-view.component';
import { PaymentViewComponent } from '../../../modules/payments/components/payment-view/payment-view.component';
import { TeamViewComponent } from '../../../modules/users/modules/teams/components/team-view/team-view.component';
import { SspViewComponent } from '../../../modules/settings/modules/ssps/components/ssp-view/ssp-view.component';
import { SegmentViewComponent } from '../../../modules/settings/modules/segments/components/segment-view/segment-view.component';
import { ExchangeSspViewComponent } from '../../../modules/exchange/components/ssp-view/ssp-view.component';
import { ExchangeDspViewComponent } from '../../../modules/exchange/components/dsp-view/dsp-view.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-main-layout',
  templateUrl: './main.layout.html',
  styleUrls: ['./main.layout.scss']
})
export class MainLayoutComponent implements OnInit {
  public profile: ProfileDetail;

  private sidebarInited = false;
  private destroyRef = inject(DestroyRef);

  constructor(
    public layoutService: LayoutService,
    public profileService: ProfileService,
    private sidebarModal: AdxadSidebarModal,
    private queryParamsService: QueryParamsService
  ) {}

  ngOnInit(): void {
    this.profileService.profile$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: profile => {
        this.profile = profile;
        this.checkSidebar();
      }
    });
  }

  /**
   * Check sm data in query params
   * May be opened only one modal
   */
  checkSidebar(): void {
    if (this.sidebarInited) {
      return;
    }
    this.sidebarInited = true;

    const query = this.queryParamsService.get('sm');
    const keys = Object.keys(query);
    if (!keys.length) {
      return;
    }

    /* get only last key */
    const lastKey = keys.slice(-1)[0];
    const componentName = lastKey.replace('sm_', '');
    let component;
    switch (componentName) {
      case 'userView': {
        component = UserViewComponent;
        break;
      }
      case 'profileView': {
        component = ProfileViewComponent;
        break;
      }
      case 'projectView': {
        component = ProjectViewComponent;
        break;
      }
      case 'campaignView': {
        component = CampaignViewComponent;
        break;
      }
      case 'creativeView': {
        component = CreativeViewComponent;
        break;
      }
      case 'moderationCreativeView': {
        component = ModerationCreativeViewComponent;
        break;
      }
      case 'paymentView': {
        component = PaymentViewComponent;
        break;
      }
      case 'teamView': {
        component = TeamViewComponent;
        break;
      }
      case 'sspView': {
        component = SspViewComponent;
        break;
      }
      case 'segmentView': {
        component = SegmentViewComponent;
        break;
      }
      case 'exchangeSspView': {
        component = ExchangeSspViewComponent;
        break;
      }
      case 'exchangeDspView': {
        component = ExchangeDspViewComponent;
        break;
      }
    }

    if (!component) {
      return;
    }

    this.sidebarModal.open(component, {
      data: {
        id: query[lastKey]
      }
    });
  }
}
