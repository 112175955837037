<ng-container *ngIf="profile">
  <adxad-header></adxad-header>
  <adxad-menu></adxad-menu>

  <main
    class="adxad-main"
    [class.sidebar-open]="layoutService.isMenuStaticOpened"
  >
    <router-outlet></router-outlet>
  </main>

  <div class="adxad-menu__overlay"
       [class.show-overlay]="layoutService.isMobileMenu"
       (click)="layoutService.toggleMobileMenu()"
  ></div>
</ng-container>
