import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileService } from '../services/profile.service';
import { LayoutService } from '../../layout/layout.service';
import { filter, map } from 'rxjs/operators';
import { APP_ROUTE } from '../routes';
import { RolesService } from '../services/roles.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileGuard {
  constructor(
    private profileService: ProfileService,
    private layoutService: LayoutService,
    private rolesService: RolesService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.profileService.profile$.pipe(
      filter(profile => profile !== null),
      map(profile => {
        const isConfirmPage = state.url.indexOf(APP_ROUTE.receiveConfirm) !== -1;
        if (isConfirmPage) {
          return !profile.basic.isVerifiedEmail || this.router.createUrlTree(['/']);
        }

        /** If is any other page & email is not confirmed
         * if is not old user, must go to confirm page
         * if is old user & not hide notification, must go to confirm page
         * if is old user & hide notification can fo
         */
        if (!profile.basic.isVerifiedEmail && (!profile.isOldUser || !this.layoutService.hideEmailConfirmNotification)) {
          return this.router.createUrlTree([APP_ROUTE.receiveConfirm]);
        }

        const isQuizPage = state.url.indexOf(APP_ROUTE.contactQuiz) !== -1;
        const needForm = profile.surveyForm && this.rolesService.isAffiliate();

        return isQuizPage ? needForm || this.router.createUrlTree(['/']) : !needForm || this.router.createUrlTree([APP_ROUTE.contactQuiz]);
      })
    );
  }
}
