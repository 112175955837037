<ng-container *ngIf="profile">

  <button type="button"
          class="mobile-menu-btn"
          (click)="layoutService.toggleMobileMenu()"
  >
    <span class="material-icons">
      menu
    </span>
  </button>

  <div class="adxad-header__logo">
    <img
      alt=""
      class="adxad-header__logo-img"
      routerLink="/"
      src="/assets/images/adxad/logo.svg"
    />
  </div>

  <div
    class="adxad-header__user-data"
    *ngIf="profile"
  >
    <section class="adxad-header__managers">
      <adxad-support-messengers
        label="{{ 'label_yourAccountManager' | transloco }}"
        [manager]="profile.basic.accountManager"
        *ngIf="profile.basic.accountManager"
        (click)="globicaService.trackEventGoals('buttonAccountManager_click');"
      ></adxad-support-messengers>

      <adxad-support-messengers
        label="{{ 'label_yourSalesManager' | transloco }}"
        [manager]="profile.basic.salesManager"
        *ngIf="profile.basic.salesManager && profile.basic.salesManager.email !== 'selfreg@adxad.com'"
        (click)="globicaService.trackEventGoals('buttonSalesManager_click');"
      ></adxad-support-messengers>
    </section>

    <section
      class="adxad-header__balance"
      *ngIf="isShowBalance"
    >
      {{ 'yourBalance' | transloco }}
      <span class="adxad-header__balance-amount">{{ profile.balance | currency }}</span>

      <button
        *ngIf="profile.showAddFunds"
        adxadButton
        class="adxad-header__add-funds-btn red"
        icon="add"
        (click)="addFunds()"
      >
        {{ 'action_addFunds' | transloco }}
      </button>
    </section>

    <section class="adxad-profile">
      <div
        *ngIf="isShowProfile"
        class="adxad-profile__link"
        (click)="openProfile()"
      >
        <i class="material-icons adxad-profile__link_mobile">person_outline</i>
        <span class="adxad-profile__link_desktop"> {{ profile.basic.email || 'Profile' }} </span>
      </div>

      <adxad-language-picker
        [isAdmin]="(rolesService.isAdmins() || rolesService.isManagers())"
        localesListPosition="right"
      ></adxad-language-picker>

      <span
        class="material-icons adxad-profile__logout pointer"
        routerLink="/auth"
        (click)="logout()"
        >exit_to_app</span
      >
    </section>
  </div>
</ng-container>
