import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { ProfileDetail } from '../../../core/interface';
import { GlobicaService } from '../../../core/services/globica.service';
import { RolesService } from '../../../core/services/roles.service';
import { ProfileViewComponent } from '../profile-view/profile-view.component';
import { AdxadSidebarModal } from '../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { AddFundsFormComponent } from '../../../modules/payments/components/add-funds-form/add-funds-form.component';
import { AdxadModal } from '../../../ui/modules/modal/modal.service';
import { ProfileService } from '../../../core/services/profile.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LayoutService } from '../../layout.service';

@Component({
  selector: 'adxad-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() hideBalance = false;
  @Input() hideProfile = false;

  public profile: ProfileDetail;
  private destroyRef = inject(DestroyRef);

  constructor(
    public profileService: ProfileService,
    public rolesService: RolesService,
    public globicaService: GlobicaService,
    public layoutService: LayoutService,
    private sidebarModal: AdxadSidebarModal,
    private modal: AdxadModal
  ) {}

  ngOnInit(): void {
    this.profileService.profile$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: profile => {
        this.profile = profile;
      }
    });
  }

  /**
   * @return {boolean} show balance & Add funds btn in header if is not admin, sales or account managers
   */
  get isShowBalance(): boolean {
    return !this.hideBalance && !this.rolesService.isManagers();
  }

  /**
   * @return {boolean} show balance & Add funds btn in header if is not admin, sales or account managers
   */
  get isShowProfile(): boolean {
    return !this.hideProfile;
  }

  /**
   * Click in add funds btn
   * Open add funds form
   */
  addFunds() {
    this.globicaService.trackEventGoals('formAddFundsButton_click');
    this.modal.open(AddFundsFormComponent, {
      width: '588px'
    });
  }

  /**
   * Open profile view in sidebar modal
   */
  openProfile(): void {
    this.sidebarModal.open(ProfileViewComponent);
  }

  logout(): void {
    this.profileService.logout();
  }
}
