import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DspService } from '../../../core/services/dsp.service';
import { Dict, Dicts, Messenger, ProfileDetail, UserForm } from '../../../core/interface';
import { AdxadModalRef } from '../../../ui/modules/modal/modal-ref';
import { forkJoin } from 'rxjs';
import { AdxadAlerts } from '../../../ui/modules/alerts/components/alerts/alerts.component';
import { AdxadValidators } from '../../../core/validators';
import { ProfileService } from '../../../core/services/profile.service';
import { TranslocoService } from '@jsverse/transloco';
import { RolesService } from '../../../core/services/roles.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss']
})
export class ProfileFormComponent implements OnInit {
  form: UntypedFormGroup;
  isLoading = false;
  isLoadingSubmit = false;
  dicts = {} as Dicts;
  vatStatus: number;
  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private dspService: DspService,
    private profileService: ProfileService,
    private modalRef: AdxadModalRef,
    private alerts: AdxadAlerts,
    private roles: RolesService,
    private translate: TranslocoService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.loadProfileData();
  }

  /**
   * Load profile form data
   */
  loadProfileData(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.profileService
      .getProfileForm()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoading = false;

          const profileData = result.data as UserForm;
          this.form.patchValue(profileData);

          if (profileData.contacts.messengers.length) {
            profileData.contacts.messengers.forEach(x => this.addMessenger(x));
          }

          this.loadData();
        },
        error: () => {
          this.isLoading = false;
          this.close();
        }
      });
  }

  /**
   * Load dictionaries:
   * Messengers, Languages, Countries
   */
  loadData(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const request = {
      limit: 200,
      page: 1
    };

    const langRequest = {
      limit: 200,
      page: 1,
      ids: [...this.contacts.get('languages').value]
    };

    const forkList = [this.dspService.getLanguages(langRequest)];

    if (this.roles.isManagers()) {
      forkList.push(this.dspService.getManagersMessengers(request));
    } else {
      forkList.push(this.dspService.getMessengers(request));
    }

    forkJoin(forkList)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        results => {
          this.isLoading = false;
          this.dicts.languages = results[0];
          this.dicts.languages.data.forEach(x => (x.selected = false));
          this.dicts.messengers = results[1];
        },
        () => {
          this.isLoading = false;
          this.close();
        }
      );
  }

  /**
   * Load lazy load dict by id
   *
   * @param {string} id
   * @param {string} value - string for search, if is value, it will new search
   */
  loadDict(id: string, value?: string): void {
    const newSearch = value && value.length;
    const dict = this.dicts[id];

    if (!dict || dict.isLoading) {
      return;
    }
    dict.isLoading = true;

    if (newSearch) {
      dict.data = [];
    }

    const request = {
      limit: 200,
      page: !newSearch && this.isLazy(id) && dict && dict.meta ? dict.meta.current_page + 1 : 1,
      search: value,
      ids: this.getIds(id) as string[]
    };

    this.dspService
      .getDictById(id, request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        result => {
          dict.isLoading = false;
          dict.data = newSearch || !this.isLazy(id) ? result.data : dict.data.concat(result.data);

          if (result.meta) {
            dict.meta = result.meta;
          }
        },
        () => (dict.isLoading = false)
      );
  }

  /**
   * @return is lazy load flag
   * @param {string} id of dict
   */
  isLazy(id: string): boolean {
    return this.dicts[id] && this.dicts[id].meta && this.dicts[id].meta.total > this.dicts[id].data.length;
  }

  /**
   * @return {string[]} selected ids from form by dict id
   * @param {string} id of dict
   */
  getIds(id: string): string[] {
    switch (id) {
      case 'languages': {
        return this.contacts.get('languages').value;
      }
    }
  }

  /**
   * Create user form
   */
  createForm(): void {
    this.form = this.fb.group({
      contacts: this.fb.group({
        phone: '',
        messengers: this.fb.array([]),
        languages: [[]]
      })
    });
  }

  /**
   * @return {UntypedFormGroup} contacts form group
   */
  get contacts(): UntypedFormGroup {
    return this.form.get('contacts') as UntypedFormGroup;
  }

  /**
   * @return {UntypedFormControl} languages form control
   */
  get languages(): UntypedFormControl {
    return this.contacts.get('languages') as UntypedFormControl;
  }

  /**
   * @param {string} id
   * @return {string} language name from dict
   */
  getLanguage(id: string): string {
    if (!this.dicts.languages) {
      return '';
    }

    return this.dicts.languages.data.find(x => x.id === id).value;
  }

  /**
   * @return dict of not selected languages
   */
  get filteredLanguages(): Dict[] {
    if (!this.dicts.languages) {
      return [];
    }

    return this.dicts.languages.data.filter(x => !x.selected);
  }

  /**
   * Add id of lang in language control
   *
   * @param {string} id
   */
  selectLang(id: string): void {
    const lang = this.dicts.languages.data.find(x => x.id === id);
    lang.selected = true;
    this.languages.value.push(id);
    this.form.markAsDirty();
  }

  /**
   * Remove language from control
   *
   * @param {number} i
   */
  removeLang(i: number): void {
    this.languages.value.splice(i, 1);
    this.form.markAsDirty();
  }

  /**
   * @return {UntypedFormArray} messengers form array
   */
  get messengers(): UntypedFormArray {
    return this.contacts.get('messengers') as UntypedFormArray;
  }

  /**
   * Add messenger to form
   */
  addMessenger(data?: Messenger): void {
    this.messengers.push(
      this.fb.group({
        type: [data?.type || ''],
        info: [data?.info || '', [Validators.required, Validators.minLength(3), AdxadValidators.en]]
      })
    );
  }

  /**
   * Remove messenger from form
   *
   * @param {number} i
   */
  removeMessenger(i: number): void {
    this.messengers.removeAt(i);
    this.form.markAsDirty();
  }

  /**
   * Edit profile
   * If vat invalid, form will not closed, country control disable
   */
  submit(): void {
    if (!this.form.valid || this.isLoadingSubmit) {
      return;
    }
    this.isLoadingSubmit = true;

    const data = this.form.getRawValue();
    this.profileService
      .changeProfile(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        result => {
          if (result.status === 'OK') {
            this.profileService.setProfile(result.data as ProfileDetail);
            this.alerts.success(this.translate.translate('alert_profileChanged'), 3000);
            this.close(true);
          }
        },
        () => (this.isLoadingSubmit = false)
      );
  }

  /**
   * Close modal
   *
   * @param {boolean} submit
   */
  close(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }
}
