import { Component, DestroyRef, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RolesService } from '../../../core/services/roles.service';
import { ApiService } from '../api.service';
import SwaggerUI from 'swagger-ui';
import { LayoutService } from '../../../layout/layout.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-api-layout',
  templateUrl: 'layout.html',
  styleUrls: ['./layout.scss']
})
export class ApiLayoutComponent implements OnInit {
  stage = 'public';

  private destroyRef = inject(DestroyRef);

  @ViewChild('apiDocumentation', { static: true }) apiDocElement: ElementRef | undefined;

  constructor(
    public roles: RolesService,
    private apiService: ApiService,
    public layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.loadData(this.stage);
  }

  loadData(stage: string): void {
    this.apiService
      .getApiDocs(stage)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => {
        SwaggerUI({
          spec: result,
          domNode: this.apiDocElement?.nativeElement,
          docExpansion: 'none'
        });
      });
  }

  toggleApi(stage: string): void {
    this.stage = stage;
    this.loadData(this.stage);
  }
}
