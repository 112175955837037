<div
  [class.--menu-opened]="this.layoutService.isMenuStaticOpened"
  class="adxad-main-container"
>
  <adxad-tabs-nav>
    <adxad-tab-link
      (click)="toggleApi('public')"
      [class.active]="stage === 'public'"
    >
      Public API
    </adxad-tab-link>
    <adxad-tab-link
      (click)="toggleApi('internal')"
      *ngIf="roles.isSuperAdmin()"
      [class.active]="stage === 'internal'"
    >
      Internal API
    </adxad-tab-link>
  </adxad-tabs-nav>

  <section #apiDocumentation></section>
</div>
