<adxad-loader
  adxadModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="close()"></adxad-modal-close-btn>

  <adxad-modal-header>
    <h2
      adxadModalTitle
      data-test="title"
    >
      {{ 'modal_titleEditProfile' | transloco }}
    </h2>
  </adxad-modal-header>

  <adxad-modal-content [formGroup]="form">
    <section
      formGroupName="contacts"
      data-test="contacts-block"
    >
      <adxad-form-field class="field-group">
        <adxad-input
          formControlName="phone"
          data-test="phone-field"
          label="{{ 'label_phoneNumber' | transloco }}"
          placeholder="{{ 'placeholder_enterPhoneNumber' | transloco }}"
        ></adxad-input>
      </adxad-form-field>

      <adxad-form-field class="field-group">
        <adxad-messengers-select
          label="{{ 'label_messengers' | transloco }}"
          [messengers]="messengers"
          [options]="dicts.messengers ? dicts.messengers.data : []"
        ></adxad-messengers-select>
      </adxad-form-field>

      <!-- Languages -->
      <adxad-form-field class="field-group">
        <adxad-select
          [options]="filteredLanguages"
          (selectOption)="selectLang($event)"
          data-test="langs-field"
          placeholder="{{ 'placeholder_selectLanguage' | transloco }}"
          label="{{ 'label_languages' | transloco }}"
        ></adxad-select>
      </adxad-form-field>

      <ng-container *ngIf="languages.value.length">
        <adxad-chip-list>
          <adxad-chip *ngFor="let id of languages.value; index as i ">
            {{ getLanguage(id) }}

            <span
              class="material-icons link"
              (click)="removeLang(i)"
            >
              close
            </span>
          </adxad-chip>
        </adxad-chip-list>
      </ng-container>
    </section>
  </adxad-modal-content>

  <adxad-modal-actions class="row">
    <div class="col">
      <button
        adxadStrokedButton
        (click)="close()"
        class="w100"
      >
        {{ 'action_cancel' | transloco }}
      </button>
    </div>

    <div class="col">
      <adxad-submit-button
        [disabled]="!form.valid || !form.dirty || isLoadingSubmit"
        (click)="submit()"
        [isLoading]="isLoadingSubmit"
      >
        {{ 'action_save' | transloco }}
      </adxad-submit-button>
    </div>
  </adxad-modal-actions>
</ng-container>
