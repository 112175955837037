<div
  class="adxad-menu"
  [class.opened]="layoutService.isMenuStaticOpened || isNavBarOpen || layoutService.isMobileMenu"
  (mouseenter)="openMenu()"
  (mouseleave)="closeMenu()"
>
  <ng-container *ngFor="let item of menuConfig">
    <div
      class="adxad-nav-btn"
      data-test="menu-item"
      *ngIf="!item.hide"
    >
      <a
        class="adxad-nav-btn__link"
        [routerLink]="item.route"
        routerLinkActive="active"
        [class._has-child]="item.items?.length"
        (click)="globica.trackEventGoals(item.globicaKey)"
      >
        <span class="adxad-nav-btn__icon material-icons-outlined"> {{ item.icon }} </span>

        <span
          class="adxad-nav-btn__label"
          [class._hidden]="!layoutService.isMenuStaticOpened && !isNavBarOpen && !layoutService.isMobileMenu"
        >
          {{ item.translated ? (item.name | transloco) : item.name }}
        </span>

        <div
          *ngIf="item.items?.length"
          class="adxad-nav-btn__open-btn"
          [class.opened]="item.isOpenSubMenu"
          (click)="toggleSubMenu($event, item)"
        >
          <span
            class="adxad-nav-btn__open-btn-icon material-icons-outlined"
            *ngIf="isNavBarOpen || layoutService.isMenuStaticOpened || layoutService.isMobileMenu"
            [class.opened]="item.isOpenSubMenu"
          >
            keyboard_arrow_down
          </span>
        </div>
      </a>

      <div
        *ngIf="item.isOpenSubMenu && (layoutService.isMenuStaticOpened || isNavBarOpen || layoutService.isMobileMenu)"
        @slideToggle
        class="adxad-nav-btn__submenu"
      >
        <ng-container *ngFor="let subItem of item.items">
          <a
            *ngIf="!subItem.hide"
            [routerLink]="subItem.route"
            class="adxad-nav-btn__submenu-item"
            routerLinkActive="active"
            (click)="globica.trackEventGoals(subItem.globicaKey)"
          >
            {{ subItem.translated ? (subItem.name | transloco) : subItem.name}}
          </a>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <div class="adxad-menu__spacer"></div>

  <div
    class="adxad-menu__more-btn"
    [class.active]="layoutService.isMenuStaticOpened"
    (click)="layoutService.toggleMenuStaticOpened()"
  >
    <i class="material-icons-outlined">keyboard_arrow_right</i>
  </div>
</div>
