import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { ProfileDetail, UserManager } from '../../../core/interface';
import { RolesService } from '../../../core/services/roles.service';
import { AdxadAlerts } from '../../../ui/modules/alerts/components/alerts/alerts.component';
import { AdxadSidebarModalRef } from '../../../ui/modules/sidebar-modal/sidebar-modal-ref';
import { AdxadModal } from '../../../ui/modules/modal/modal.service';
import { ProfileFormComponent } from '../profile-form/profile-form.component';
import { PasswordFormComponent } from '../password-form/password-form.component';
import { slideToggleAnimation } from '../../../ui/animations';
import { QueryParamsService } from '../../../core/services/query-params.service';
import { PaymentInfoFormComponent } from '../../../gui/components/payment-info-form/payment-info-form.component';
import { filter } from 'rxjs/operators';
import { AddFundsFormComponent } from '../../../modules/payments/components/add-funds-form/add-funds-form.component';
import { ProfileService } from '../../../core/services/profile.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss'],
  animations: [slideToggleAnimation]
})
export class ProfileViewComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public isOpenSection = {
    account: true,
    accountManager: true,
    salesManager: true,
    contacts: true,
    payment: true
  };
  profile: ProfileDetail;

  private destroyRef = inject(DestroyRef);

  constructor(
    public profileService: ProfileService,
    public roles: RolesService,
    private alerts: AdxadAlerts,
    private modalRef: AdxadSidebarModalRef,
    private queryParamsService: QueryParamsService,
    private modal: AdxadModal
  ) {}

  ngOnInit(): void {
    this.queryParamsService.add('sm_profileView', true);

    this.profileService.profile$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: profile => {
        this.isLoading = false;
        this.profile = profile;
      }
    });
  }

  /**
   * @return {UserManager} account manager data
   */
  get accountManager(): UserManager {
    return this.profile.basic.accountManager;
  }

  /**
   * @return {UserManager} sales manager data
   */
  get salesManager(): UserManager {
    return this.profile.basic.salesManager;
  }

  /**
   * Open edit profile form
   * If form is submit, reload profile data
   */
  openEditForm(): void {
    this.modal
      .open(ProfileFormComponent, {
        width: '560px',
        data: {}
      })
      .afterClosed.pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(value => value?.submit)
      )
      .subscribe(() => this.reloadProfile());
  }

  /**
   * Reload profile data
   */
  reloadProfile(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.profileService.loadProfile();
  }

  /**
   * Open password form
   */
  openPasswordForm(): void {
    this.modal.open(PasswordFormComponent, {
      width: '560px',
      data: this.profile
    });
  }

  /**
   * Open payment info form
   */
  openPaymentInfoForm(): void {
    this.modal
      .open(PaymentInfoFormComponent, {
        width: '560px',
        data: {
          isAdvertEdit: true
        }
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        if (value && value.submit) {
          this.reloadProfile();
        }
      });
  }

  /**
   * Open add funds form
   */
  submitBalance(): void {
    this.modal.open(AddFundsFormComponent, {
      width: '588px'
    });
  }

  /**
   * @return {boolean} show payment info flag
   */
  get isPaymentView(): boolean {
    return (
      (!this.roles.isManagers() && this.profile.payment !== null) || (!this.roles.isManagers() && this.profile.paymentOnModeration !== null)
    );
  }

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   */
  close(submit?): void {
    this.modalRef.close({ submit: submit });
  }

  ngOnDestroy(): void {
    this.queryParamsService.remove('sm_profileView');
  }
}
