import { Routes } from '@angular/router';
import { MainLayoutComponent } from './layout/layouts/main-layout/main.layout';
import { RolesGuard } from './core/guard/roles.guard';
import { MainGuard } from './core/guard/main.guard';
import { ProfileGuard } from './core/guard/profile.guard';
import { NotFoundPageComponent } from './gui/components/404/not-found.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ApiLayoutComponent } from './modules/api/layout/layout';
import { APP_ROUTE } from './core/routes';
import { PaymentConfirmStatus } from './core/interface';
import { HideMenuLayout } from './layout/layouts/hide-menu-layout/hide-menu.layout';
import { ReceiveConfirmComponent } from './modules/simple-pages/components/receive-confirm/receive-confirm.component';
import { ContactQuizComponent } from './modules/simple-pages/components/contact-quiz/contact-quiz.component';

export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: APP_ROUTE.tradeDesk,
        pathMatch: 'full'
      },
      {
        path: APP_ROUTE.tradeDesk,
        loadChildren: () => import('./modules/trade-desk/trade-desk.module').then(m => m.TradeDeskModule)
      },
      {
        path: APP_ROUTE.reports,
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: APP_ROUTE.moderation,
        loadChildren: () => import('./modules/moderation/moderation.module').then(m => m.ModerationModule)
      },
      {
        path: APP_ROUTE.payments,
        loadChildren: () => import('./modules/payments/payments.module').then(m => m.PaymentsModule)
      },
      {
        path: APP_ROUTE.help,
        loadChildren: () => import('./modules/help/help.module').then(m => m.HelpModule)
      },
      {
        path: APP_ROUTE.users,
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule)
      },
      {
        path: APP_ROUTE.settings,
        loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: APP_ROUTE.exchange,
        loadChildren: () => import('./modules/exchange/exchange.module').then(m => m.ExchangeModule)
      },
      {
        path: APP_ROUTE.api,
        component: ApiLayoutComponent,
        title: 'API Documentation',
        data: {
          pageType: 'APIDOC'
        }
      }
    ],
    canActivate: [MainGuard, ProfileGuard, RolesGuard]
  },
  {
    path: APP_ROUTE.paymentSuccess,
    loadComponent: () =>
      import('./modules/simple-pages/components/payment-confirm-page/payment-confirm-page.component').then(
        c => c.PaymentConfirmPageComponent
      ),
    title: 'Payment accepted.',
    data: {
      pageType: 'PAYMENT_SUCCESS',
      type: 'success' as PaymentConfirmStatus
    }
  },
  {
    path: APP_ROUTE.receiveConfirm,
    canActivate: [MainGuard, ProfileGuard],
    component: HideMenuLayout,
    children: [
      {
        path: '',
        component: ReceiveConfirmComponent
      }
    ]
  },
  {
    path: APP_ROUTE.contactQuiz,
    canActivate: [MainGuard, ProfileGuard],
    component: HideMenuLayout,
    children: [
      {
        path: '',
        component: ContactQuizComponent
      }
    ]
  },
  {
    path: APP_ROUTE.paymentFail,
    loadComponent: () =>
      import('./modules/simple-pages/components/payment-confirm-page/payment-confirm-page.component').then(
        c => c.PaymentConfirmPageComponent
      ),
    title: 'Payment declined.',
    data: {
      pageType: 'PAYMENT_FAIL',
      type: 'fail' as PaymentConfirmStatus
    }
  },
  {
    path: APP_ROUTE.paymentInProgress,
    loadComponent: () =>
      import('./modules/simple-pages/components/payment-confirm-page/payment-confirm-page.component').then(
        c => c.PaymentConfirmPageComponent
      ),
    title: 'Payment in progress.',
    data: {
      pageType: 'PAYMENT_IN_PROGRESS',
      type: 'inProgress' as PaymentConfirmStatus
    }
  },
  {
    path: APP_ROUTE.auth,
    canActivate: [AuthGuard],
    loadChildren: () => import('./auth/auth.module').then(module => module.AuthModule)
  },
  {
    path: '**',
    component: NotFoundPageComponent,
    title: '404 - Page not found'
  }
];
